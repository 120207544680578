/* home page css start here */
* body {
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: #f3f3f3;
  color: rgb(0, 0, 0);
}
/* loading sty */
.loder {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
hr.loder-hr {
  position: absolute;
  border-top: 1px dashed saddlebrown;
  top: 0%;
  width: 100%;
  overflow: hidden;
}
/* slider buttons */
.flickity-page-dots {
  bottom: 5px;
}
.flickity-page-dots .dot {
  width: 6px;
  height: 6px;
  margin: 0 8px;
  background: saddlebrown;
}
.flickity-prev-next-button {
  top: 50%;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: translateY(-50%);
  box-shadow: -2px -2px 3px -3px rgb(0, 0, 0), 2px -2px 3px -3px rgb(0, 0, 0),
    -1px 2px 3px -1px rgb(0, 0, 0);
}
.flickity-button:hover {
  background: rgb(255, 255, 255);
  cursor: pointer;
}
.flickity-button:active {
  opacity: 1;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: pointer;
}
.flickity-button-icon {
  fill: rgb(0, 0, 0);
}
.flickity-button {
  position: absolute;
  background: hsl(0, 0%, 100%);
  border: none;
  color: rgb(0, 0, 0);
}
.PrivateTabIndicator-colorPrimary-201 {
  background-color: #ffffff1c;
  border-radius: 10px;
}
.PrivateTabIndicator-root-200 {
  width: 100%;
  bottom: 0;
  background-color: white;
  height: 100%;
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #d62929;
}
/* slider buttons end */
.mdl-layout__header {
  background-color: #ffffff;
}
.mdl-tooltip {
  background-color: #000;
}
a {
  text-decoration: none;
}
main::-webkit-scrollbar {
  width: 10px;
  background-color: burlywood;
  border-radius: 5px;
}
main::-webkit-scrollbar-thumb {
  background-color: #b11313;
  border-radius: 5px;
  cursor: move;
}
.mdl-layout-title {
  margin-left: -3%;
  padding: 5px 15px;
  border-radius: 4px;
  text-decoration: none;
}
.mdl-layout-title > img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  background-color: #dedede;
  object-position: center;
  border-radius: 5px;
  padding: 5px;
}
.mdl-layout-title-mble {
  width: 24px;
  height: 24px;
  display: none;
}
.mdl-layout-title-mble > img {
  width: 100%;
  height: 100%;
}

.bottom-header-div {
  /* margin: 20px 0px 0px 0px; */
  height: 36px;
  background-color: burlywood;
  /* border-bottom: 2px dotted #2d2d2d; */
}

.mdl-layout__header-row .mdl-navigation__link {
  color: #672e06;
  font-family: sans-serif;
  opacity: 0.8;
  text-transform: capitalize;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
}
/* signup form-edit */
.form-outer-div {
  /* border: 2px solid #212121; */
  padding: 10px 20px;
  border-radius: 8px;
  background-color: burlywood;
  margin-top: 20px;
  color: black;
}
.form-form {
  width: 90%;
}
.form-label {
  font-size: 16px;
  font-weight: 600;
}
.text-center {
  margin: 0px;
  font-size: 28px;
  /* font-weight: 700; */
  padding: 8px 0px;
}
/* header apps menu */
.apps {
  opacity: 1;
}
header .mdl-menu {
  left: -100px;
}
header .mdl-menu__container.is-visible .mdl-menu {
  background: #171616;
}
.mdl-menu__item:active {
  background-color: #101010;
}
.mdl-menu__item:focus {
  outline: none;
  background-color: #232222;
}
.mdl-menu__item:hover {
  background-color: #191717;
}
.mdl-menu__item {
  color: #fff;
  font-weight: 500;
  font-family: Roboto, "Segoe UI", Tahoma, sans-serif;
  text-transform: capitalize;
}
.mdl-menu__item img {
  width: 50px;
  height: 50px;
}
/* end */
.mdl-layout__header-row .mdl-navigation__link:hover {
  color: #e02f2f;
}
@media screen and (max-width: 700px) {
  .mdl-layout__header-row .mdl-navigation__link {
    padding: 5px 10px;
  }
  .mdl-layout-title {
    font-size: 14px;
    margin-left: -10%;
    padding: 5px 15px;
  }
  #mdl-navigation__link_text {
    display: block;
  }
}
.mdl-button {
  color: #fff;
}
.mdl-grid {
  margin: 0%;
  color: #777777;
}

.grid-top-text {
  border-bottom: 1px solid rgb(73, 73, 73);
  padding-bottom: 10px;
}
.grid-top-text .mdl-button {
  font-family: "Courier New", Courier, monospace;
  font-size: 20px;
  text-transform: capitalize;
  color: #f7f7f7;
  border: 1px solid rgb(59, 58, 58);
  font-weight: 600;
}
/* seach box css */
.top_search_fied_holder {
  padding: 0px 0px 0px 0px;
  border-bottom: 0px;
  width: 100%;
  height: 100%;
}
.top_search_field {
  margin: 0px 0px 0px 30px;
  height: 100%;
}
.search-holding-div-form {
  height: 100%;
  padding: 0px;
  margin-top: 5px;
}
form {
  outline: 0;
  float: left;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0), 0 1px 2px rgba(0, 0, 0, 0);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0), 0 1px 2px rgba(0, 0, 0, 0);
  width: 100%;
  margin: 0px;
}
input::placeholder {
  color: rgb(34, 34, 34);
  font-family: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 15px;
}

.textbox {
  outline: 0;
  height: 36px;
  width: 100%;
  line-height: 36px;
  padding: 0 16px;
  /* border: 1px solid #252525; */
  border-right: 0px;
  border-radius: 5px 0px 0px 5px;
  background-color: #f1f1f1;
  transition: background-color 0.4s ease-out;
  color: #161616;
  border: 0;
  font-weight: 600;
  font-family: inherit;
}

.textbox:focus {
  outline: 0;
  background-color: rgb(238, 238, 238);
  color: rgb(8, 8, 8);
  border: 0px;
  /* border: 1px solid #deb887; */
}

/* end */

/* ----------------------------------------------------------------------------------------- */
/* Home Page Css Lives Here */
/* header carousel  */

.carousel.tc {
  color: rgb(0, 0, 0);
  padding: 20px 30px;
  background: burlywood;
}

.carousel-cell.tccc01 {
  width: 75%;
  height: 300px;
  margin-right: 5px;
  background: #f9f9f9;
  border-radius: 5px;
  counter-increment: carousel-cell;
}
.carouselback {
  position: relative;
  max-width: 200px;
}
/* tc = top carousel */
.producttopnamediv {
  padding: 5px;
  border-radius: 5px;
}
.producttopnamediv:hover {
  background: #8b451329;
  padding: 5px;
  border-radius: 5px;
}

.tccc01 {
  background-blend-mode: darken;
  background-repeat: no-repeat;
  background-position: center;
}
.tc01 {
  border-right: 1px solid #dadada;
  width: 35%;
  height: 100%;
  float: left;
  background-color: #fff;
  overflow: hidden;
  border-radius: 5px;
}
.tc01 > a > img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 5px 0px 0px 5px;
  position: relative;
  object-position: top;
  transform: scale(1);
  transition: transform 0.3s ease-in;
}
.tc01 > a > img:hover {
  transform: scale(0.95);
  border-radius: 5px;
}
.top_img_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: black; */
  top: 0;
}
.tc02 {
  float: left;
  width: 60%;
  height: 100%;
  padding: 10px;
  margin-left: 2%;
  overflow: hidden;
}
.tc02 > a.tchl01 > h3 {
  color: rgb(8, 8, 8);
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  max-height: 21%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0px;
  padding-top: 8px;
  padding: 8px;
}
.tcd01-btn {
  padding-top: 8px;
  border-top: 1px solid #252525;
}
.tc02 > a.tcwl01 {
  color: rgb(63, 63, 63);
  margin: 0px;
  font-family: inherit;
  font-weight: 700;
}
.tc02 > a > h3:hover {
  color: rgb(20, 20, 20);
  background-color: #e0e0e0de;
  border-radius: 5px;
}
.tc02 > h6 {
  margin: 5px;
  font-weight: 900;
  color: #ff9900;
  font-size: 16px;
}
.tc02 > h2.tcd01 {
  max-height: 32%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  line-height: 20px;
  margin: 0px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding: 8px 0px;
  border-top: 1px solid #dadada;
}
/* buy button */
.tc02 > a.tcl01 {
  color: rgb(8, 8, 8);
  background-color: white;
  padding: 8px 15px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
  transition-delay: 0.6s;
  transition: r ease-in-out;
}
.tc02 > a.tcl01:hover {
  color: #bb1313;
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.55),
    0 3px 3px -2px rgba(0, 0, 0, 0.45), 0 1px 8px 0 rgba(0, 0, 0, 0.84);
}
.tc02 > a.tcl01 > i {
  font-size: 14px;
}
.gcn1 {
  padding: 0px 0px 8px 0px;
  background-color: #deb88700;
  border-radius: 5px;
  margin: 10px 10px 2px 10px;
  box-shadow: 0 0 black;
  /* border-bottom: 2px dotted #2b2b2b; */
}
.gcn1 > h1 {
  font-size: 28px;
  float: left;
  margin: 0px;
  color: saddlebrown;
  border-radius: 5px;
  padding: 2px 10px;
  background: burlywood;
  font-family: inherit;
}
.btn-va {
  color: #ffffff;
  background-color: burlywood;
  padding: 3px 10px;
  border-radius: 3px;
  text-transform: capitalize;
  font-weight: 700;
  margin: 10px 10px 0px;
}
.btn-va:hover {
  color: saddlebrown;
}
.gcn1 > a {
  float: right;
}
.gcn1-small {
  display: none;
}
@media screen and (max-width: 460px) {
  .gcn1 {
    display: none;
  }
  .gcn1-small {
    display: block;
  }
  .gcn1-small {
    padding: 0px 0px 8px 0px;
    background-color: #deb88700;
    border-radius: 5px;
    margin: 10px 10px 2px 10px;
    box-shadow: 0 0 black;
    border: 1px solid #b7b7b7;
    margin: 50px 10px 2px 10px;
  }
  .gcn1-small > h1 {
    font-size: 28px;
    float: left;
    margin: 0px;
    color: chocolate;
    border-radius: 5px;
    padding: 2px 10px;
    background: #f5deb3;
    font-family: inherit;
  }
  .btn-va {
    color: #299c29;
    background-color: #b9e2b9;
    padding: 3px 15px;
    border-radius: 3px;
    text-transform: capitalize;
    font-weight: 700;
    margin: 10px 10px 0px;
  }
  .btn-va:hover {
    color: saddlebrown;
  }
  .gcn1-small > a {
    float: right;
  }
}
.gci2 {
  padding: 5px;
  height: auto;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.55),
    0 1px 1px -1px rgba(0, 0, 0, 0.45), 0 1px 1px 0 rgba(0, 0, 0, 0.84);
  border-radius: 5px;
  background-color: #000;
  cursor: pointer;
  transition: box-shadow 0.1s ease-in-out;
  border: 2px solid #1d1d1d;
}
.gci2:hover {
  box-shadow: 3px -3px 5px 0 rgba(0, 0, 0, 0.55),
    1px 0px 3px -2px rgba(0, 0, 0, 0.45), -3px 4px 8px 0 rgba(0, 0, 0, 0.84);
}
img.gcii {
  width: 100%;
  height: 200px;
  object-fit: scale-down;
  object-position: center;
  float: left;
  margin-right: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}
a.gcih > h4 {
  color: #a7a7a7;
  font-size: 16px;
  width: 100%;
  line-height: 18px;
  text-transform: capitalize;
  font-weight: 600;
  height: 56px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0px;
  padding-top: 8px;
  word-spacing: 0px;
  /* border-top: 1px solid #424242; */
  font-family: monospace;
  margin-top: 10px;
}
a.gcih > h4:hover {
  color: #fff;
}
h5.gcip {
  margin: 5px;
  font-weight: 900;
  font-size: 16px;
  color: #ff9900;
}
.gcip {
  background-color: "#1b1a1a";
  border: "1px solid #252525";
  color: "#fff";
  font-weight: "800";
  margin: "10px 0px";
}
.gciw {
  margin: 0px 0px 10px 0px;
  border-bottom: 1px solid #252525;
}
.gciw > span {
  font-size: 14px;
  font-weight: 700;
  color: #8c8c8c;
}
.gciw > a {
  color: #969696;
  text-transform: capitalize;
  font-weight: 500;
}

div.gcid > p {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 14px;
  margin: 0px;
  height: 100px;
  color: #fff;
  padding-top: 10px;
  font-family: monospace;
  border-top: 1px solid #252525;
}

.gci2 > a.btn-va {
  color: burlywood;
  background-color: white;
  padding: 8px 15px;
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 500;
  transition: ease-in-out;
  transition-delay: 0.2s;
}
.gci2 > a.btn-va:hover {
  color: saddlebrown;
}
.gci2 > a.btn-va > i {
  font-size: 14px;
}
/* carousel second = cs2 */
/* .flickity-viewport {
  height: 400px;
} */
.carousel.cs2 {
  color: #121212;
  border: 1px solid #cecece;
  padding: 10px 0px;
  background: #8b451385;
}
.carousel-cell.cs2c {
  height: 300px;
  width: 220px;
  margin: 3px;
  border-radius: 5px;
  padding-bottom: 10px;
  border: 1px solid burlywood;
  background-color: burlywood;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.1s ease-in-out;
}
.carousel-cell.cs2c:hover {
  /* box-shadow: 1px 1px 3px 3px rgba(76, 76, 76, 0.55),
    0px -20px 3px 3px rgba(41, 41, 41, 0.45),
    -1px -1px 3px 3px rgba(39, 39, 39, 0.84); */
}
.carousel-cell.cs2c > div > a > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #fff;
  border-radius: 5px 5px 0px 0px;
  transform: scale(1);
  transition: transform 0.5s ease-out;
}
.carousel-cell.cs2c > div.cs2cdi {
  width: 100%;
  height: 70%;
  object-fit: contain;
  background-color: #e0e0e0;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
}
.carousel-cell.cs2c > div > a > img:hover {
  transform: scale(1.2);
}
.carousel-cell.cs2c > a > h1.cs2ch {
  color: saddlebrown;
  font-size: 14px;
  margin: 0px;
  line-height: 16px;
  padding: 10px 5px;
  font-family: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 700;
}
.carousel-cell.cs2c > a > h1.cs2ch:hover {
  color: rgb(99, 47, 11);
  background: #ffffff47;
  border-radius: 5px;
}
.carousel-cell.cs2c > h4.cs2cp {
  margin: 0px;
  font-size: 18px;
  color: #ff9900;
  padding: 8px 14px;
}
/* end */
/* about the website  cloumn*/
.abtc {
  margin: 3%;
  border: 2px solid #2f2d2d;
  border-radius: 5px;
  background-color: #1b1b1b;
}
.abtc > .tabtw > p {
  padding: 20px;
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
}
.abtc > .tabtr > p {
  padding: 20px;
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
}
.abtc > .tabtw > h1 {
  font-size: 20px;
  padding: 4px 10px;
  background-color: white;
  color: black;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 5px;
  margin: 10px;
  max-width: 100px;
}
.abtc > .tabtr > h1 {
  font-size: 20px;
  padding: 4px 10px;
  background-color: white;
  color: black;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 5px;
  margin: 10px;
  width: 200px;
}
.abtc > .tabtr > h3 {
  padding: 20px;
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
  margin: 0px;
}
.abtc > .tabtr > h3 > a > h1 {
  margin: 0px;
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  background-color: white;
  padding: 8px 15px;
  border-radius: 5px;
  max-width: 100px;
  font-weight: 600;
}
.abtc > .tabtr > h3 > a > h1:hover {
  background-color: rgb(214, 208, 208);
}
/* end */
/* product page css */
.pc {
  height: auto;
  width: 100%;
  object-fit: cover;
  background: burlywood;
  overflow: hidden;
}
.pcc1 {
  height: 500px;
  border-radius: 10px;
  background-color: white;
}
img.pci {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}
.pciacde {
  height: "490px";
}
img.pci:hover {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.pch {
  font-size: 20px;
  color: white;
  max-height: 16%;
  margin: 0px;
  overflow: hidden;
  font-weight: 700;
}
.pcc2 > .price {
  font-size: 22px;
  font-weight: 700;
  color: #ff9900;
  padding: 4px 30px;
  border-radius: 5px;
}
.pcd {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin: 0px;
  padding-top: 10px;
  max-height: 40%;
  overflow: hidden;
  padding-bottom: 8px;
}
.pcc2 > a.btn-va > i {
  font-size: 16px;
}
.share_btn {
  padding: 8px 33px;
}
/* page loader */
.pl {
  height: 60px;
  background-color: black;
}
/* end */
/* -------------------------------------------------------------------------------------------------- */
/* small screen adjustments */
/* main page */
@media (max-width: 479px) {
  .mdl-grid {
    padding: 0px;
  }
}
@media screen and (max-width: 768px) {
  .carousel-cell.tccc01 {
    width: 100%;
  }
  .pcc1 {
    height: 460px;
    width: 10%;
  }
  .tc01 {
    width: 40%;
    height: 100%;
  }
  .tc02 {
    float: left;
    width: 50%;
    height: 100%;
    padding: 0px 10px;
  }
}

@media screen and (max-width: 460px) {
  .tc01 {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .tc02 > h2.tcd01 {
    max-height: 27%;
    font-size: 12px;
    border: 0px;
    margin-bottom: 0px;
    margin-right: 10px;
  }
  .tc02 > a.tcwl01 {
    display: none;
  }
  .tcd01-btn {
    padding-top: 4px;
    border: 0px;
    margin-left: -18px;
  }
  .tc02 > a.tchl01 > h3 {
    font-size: 14px;
    line-height: 16px;
    width: 95%;
    max-height: 16%;
  }
  .tc02 > a.tcl01 {
    padding: 4px 10px;
  }

  .gcn1 > h1 {
    font-size: 18px;
  }
  .btn-va {
    padding: 4px 10px;
  }
  a.gcih > h4 {
    font-size: 14px;
    line-height: 14px;
    max-height: 56px;
    width: 100%;
  }
  div.gcid > p {
    max-height: 98px;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 20px;
  }
  .gci2 > a.btn-va {
    padding: 4px 10px;
  }
  .bottom-header-div {
    padding: 0% 10%;
  }
  .pc {
    height: auto;
    padding: 0px;
  }
  .pcc1 {
    height: 460px;
  }
  .gci2 {
    height: auto;
    padding: 10px;
  }
  img.gcii {
    width: 100%;
    height: 200px;
    object-fit: scale-down;
  }
  img.pci {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .pciacde {
    height: "200px";
  }
  .pch {
    font-size: 16px;
    max-height: 16%;
    margin: 0px;
  }
  .pcd {
    font-size: 13px;
    line-height: 20px;
  }

  .tc02 {
    float: left;
    width: 98%;
    height: 100%;
    top: 0%;
    background: linear-gradient(
      180deg,
      #deb88796,
      #deb887bf,
      #deb887ba,
      burlywood
    );
    position: relative;
    padding: 20px;
    margin: 0px;
  }
  .carousel.tc {
    padding: 0px;
    margin: 0px;
  }
}
/* product small adustments */

/* about page */
.tabtw > h1 {
  font-size: 20px;
  padding: 8px 15px;
  background-color: white;
  color: black;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 5px;
}

/* register page css goes here */
/* --------------------------------------- */

.main_form_div {
  height: auto;
  /* border: 2px solid #252424; */
  border-radius: 8px;
  background-color: burlywood;
  margin-top: 10px;
}
.main_form_div > form {
  padding: 40px 10px 20px 40px;
  overflow: hidden;
  width: 50%;
  margin-top: 10px;
}
form > fieldset {
  margin-top: 10px;
}
.top-name-reg-ajar {
  color: #af1820;
  font-family: "Courier New", Courier, monospace;
  font-size: 20px;
  font-weight: bold;
  margin-left: 0px;
  border: 1px solid #3e3c3c;
  padding: 5px 15px;
  border-radius: 4px;
  text-decoration: none;
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.55),
    0 3px 3px -2px rgba(0, 0, 0, 0.45), 0 1px 8px 0 rgba(0, 0, 0, 0.15); */
  margin-top: 5px;
}
.top-text-reg-ajar {
  font-size: 20px;
  font-family: "Google Sans", arial, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  margin: 0;
  color: black;
  padding: 20px 0px 0px 20px;
}

/* register fields css */
.input.large-ajar-field::placeholder {
  color: rgb(122, 122, 122);
  font-family: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 15px;
}

input.large-ajar-field {
  outline: 0;
  height: 38px;
  width: 90%;
  line-height: 38px;
  padding: 0 16px;
  border: 1px solid #353535;
  border-radius: 5px;
  background-color: rgb(31, 31, 31);
  transition: background-color 0.4s ease-out;
  color: #fcfcfc;
  /* border: 0; */
  float: left;
}

input.large-ajar-field:focus {
  outline: 0;
  background-color: rgba(20, 20, 20, 0.43);
  color: rgb(253, 253, 253);
  border: 1px solid #272424;
}
/* small-ajar-field */
.input.small-ajar-field::placeholder {
  color: rgb(122, 122, 122);
  font-family: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 15px;
}

input.small-ajar-field {
  outline: 0;
  height: 38px;
  width: 100%;
  line-height: 38px;
  padding: 0 16px;
  border: 1px solid #353535;
  border-radius: 5px;
  background-color: rgb(31, 31, 31);
  transition: background-color 0.4s ease-out;
  color: #fcfcfc;
  /* border: 0; */
  float: left;
}

input.small-ajar-field:focus {
  outline: 0;
  background-color: rgba(20, 20, 20, 0.43);
  color: rgb(253, 253, 253);
  border: 1px solid #272424;
}

input::placeholder {
  color: rgb(122, 122, 122);
  font-family: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  font-size: 15px;
}
input {
  outline: 0;
  height: 38px;
  width: 100%;
  line-height: 38px;
  padding: 0 16px;
  border: 1px solid #353535;
  border-radius: 5px;
  background-color: rgb(31, 31, 31);
  transition: background-color 0.4s ease-out;
  color: #fcfcfc;
  /* border: 0; */
  float: left;
  margin: 8px 0px;
}
/* input:focus {
  outline: 0;
  background-color: rgba(20, 20, 20, 0.877);
  color: rgb(253, 253, 253);
  border: 1px solid #272424;
} */

/* register */
.ajar-half-reg-space-img {
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}
.ajar-half-reg-space-img > img {
  object-fit: scale-down;
  height: 100%;
  width: 100%;
  object-position: center;
}
.button-btn-ajar {
  float: left;
}
.mdl-button {
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 4px;
}
.right-button-reg {
  float: right;
}
/* ----------------------------------------- */
/* register page css ends here */

/* profile page css */
/* ----------------------- */
.outer-profile-main {
  overflow: hidden;
}
.outer-profile-main-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(90deg, #0c0c0c00, #0c0c0c8f, rgb(0, 0, 0));
}
.outer-profile-grid {
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}
.profile-side-holding-div {
  background-color: #0c0c0c;

  height: 100vh;
  overflow-y: scroll;
  padding: 0px;
  margin: 0px;
}
.profile-side-holding-div::-webkit-scrollbar {
  width: 2px;
  background-color: #242121;
}
.profile-side-holding-div::-webkit-scrollbar-thumb {
  background-color: #8b0000;
}
.profile-pics-div {
  height: 200px;
  width: 100%;
  background-color: black;
}
.background-pic-div {
  width: 100%;
  height: 100%;
  position: relative;
}
.background-pic-div > img {
  object-fit: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.background-pic-div-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(180deg, transparent, #0c0c0cd1, #0c0c0c);
}
.profile-main-pic-div {
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: #171616;
  top: 20px;
  left: 35%;
  border-radius: 50%;
}
.profile-main-pic-div > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
.profile-main-name-div {
  position: absolute;
  width: 150px;
  max-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 110px;
  left: 25%;
}
.profile-main-name-div > h4 {
  font-size: 15px;
  text-overflow: ellipsis;
  line-height: 15px;
  font-weight: 600;
  color: #fff;
  margin: 0px;
  padding: 0px;
}
.profile-side-holding-nav-outer-div {
  height: auto;
  padding: 3% 3% 3% 10%;
}
.profile-side-holding-nav-item-div {
  width: 80%;
  padding: 10px 20px 10px 30px;
  border-radius: 0px 50px 50px 0px;
  border: 1px solid #272727;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);

  margin-bottom: 5px;
}
.active-profile-side {
  border-left: 3px solid #bb1616;
}
.profile-side-holding-nav-item-div:hover {
  background-color: #292929;
}
.profile-side-holding-nav-item-div > i {
  color: #fff;
  float: left;
  margin-right: 20px;
  border-right: 1px solid #777676;
  padding-right: 5px;
}
.profile-side-holding-nav-item-div > h6 {
  margin: 0px;
  padding: 0px;
  color: white;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
}

/* ----------------------- */

/* profile page ends */
/* profile_2 horizontal inline profile */
.profile-top-outer-grid {
  padding: 0px;
}
.background-image-div {
  width: 100%;
  padding: 0px;
  margin: 0px;
  background-color: black;
  height: 400px;
  border: 0px;
  position: relative;
}
.background-image-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.background-image-div-overlay-div {
  background: #0000005e;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.profile-user-outer-grid {
  width: 100%;
  height: 50%;
  position: relative;
  top: 35%;
}
.profile-image-holding-div {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}
.profile-image-img {
  width: 100%;
  height: 100%;
  background-position: center;
  object-fit: cover;
}
.profile-name-holding-div {
  max-height: 160px;
  overflow: hidden;
  border-right: 1px solid #424242;
}
.profile-name-h1 {
  max-height: 50px;
  overflow: hidden;
  font-size: 30px;
  white-space: nowrap;
  font-weight: 600;
  text-overflow: ellipsis;
  text-transform: capitalize;
  margin: 10% 0% 0% 0%;
  padding-right: 10px;
  text-shadow: 5px 5px 20px black;
  color: white;
}
.tags-user-outer-grid {
}
.profile-action-tab-mdl-div {
  border: 1px solid #cecece;
  border-radius: 8px;
  margin-top: "30px";
}
.tag-holding-div {
  margin: 0px 8px;
  color: #fff;
}
/* profile tab css */
.username-h2 {
  margin: 0px;
  text-transform: capitalize;
  font-size: 20px;
  color: black;
}
.about-div {
  margin-top: 10px;
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #232323;
}
.about-p {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  padding-bottom: 3px;
  margin: 0px;
  text-transform: capitalize;
  color: grey;
}
.about-h3 {
  margin: 0px;
  font-size: 14px;
  line-height: 21px;
  color: #121212;
  word-spacing: -1px;
}
.joined-div {
  margin-top: 10px;
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #232323;
}
.joined-p {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  padding-bottom: 3px;
  margin: 0px;
  text-transform: capitalize;
  color: grey;
}
.joined-span {
  color: rgb(5, 5, 5);
}
.about-image-div {
  max-height: 300px;
  overflow: hidden;
}
.about-image-div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
}
.following-profile-div {
  margin-top: 3%;
}
@media screen and (max-width: 900px) {
  .about-image-div {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .profile-name-holding-div {
    max-height: 160px;
    margin-left: 20%;
    border-right: 0px;
    width: 100%;
  }
  .profile-name-h1 {
    width: 80%;
    font-size: 16px;
    margin: 0% 0% 0% 0%;
    margin-left: 20%;
  }
  .following-profile-div {
    display: none;
  }
}

/* profile_2 ends */
/* mdl eedit form */
.form-input-top-label {
  margin: 0px;
  font-size: 20px;
  /* font-weight: 00; */
  margin-bottom: 10px;
  text-transform: capitalize;
  color: #000000;
}
.about-me-floting-field {
  width: 80%;
  min-height: 200px;
  background-color: rgb(27, 26, 26);
  color: white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgb(58, 55, 55);
  font-size: 14px;
}

.about-me-floting-field::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #3d3d3d;
}
.about-me-floting-field::-webkit-scrollbar-thumb {
  background-color: #dc2424;
  cursor: pointer;
  border-radius: 10px;
}
/* stores page css goes here */
.store-top-outer-grid {
  padding: 0px;
}
.store-background-image-div {
  width: 100%;
  padding: 0px;
  background: #000000;
  margin: 0px;
  height: 500px;
  border: 0px;
  position: relative;
}
.store-background-image-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}
.store-background-image-div-overlay-div {
  background: rgb(0 0 0 / 51%);
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.store-info-grid-div {
  position: relative;
  top: 10%;
}
.store-name-image-div {
  overflow: hidden;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.781), rgb(0, 0, 0));
  position: absolute;
  top: 79%;
  left: 65%;
  border-radius: 10px;
}
.store-name-image-img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 10px;
}
.store-logo-image-div {
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  /* padding: 10px; */
}
.store-logo-image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.store-name-div {
  padding: 0px;
}
.store-name-h1 {
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  font-size: 32px;
  font-weight: 700;
  text-transform: capitalize;
  padding-left: 10px;
  white-space: nowrap;
  margin: 10px 0px 0px 0px;
}
.store-website-link-a {
  font-size: 14px;
  line-height: 14px;
  margin: 0px 0px 0px 10px;
  color: darkgoldenrod;
}
.store-description-div {
  margin-left: 10%;
  overflow: hidden;
}
.store-description-h1 {
  margin: 0px;
  max-height: 92px;
  font-size: 14px;
  text-overflow: ellipsis;
  color: white;
  font-family: monospace;
  overflow: hidden;
  font-weight: 800;
}
.store-social-grid-div {
  position: relative;
  top: 10%;
}
.store-social-grid-div-cell {
  background-color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  width: auto;
  border: 1px solid #292929;
  position: absolute;
  left: 60%;
}
.store-chips-div {
  margin-top: 10px;
}
.store-catlog-h5 {
  margin: 5px;
  margin-top: 10px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  color: #bdbdbd;
}
.store-catlog-span {
  font-size: 14px;
  font-weight: 600;
  margin-left: 3px;
  color: white;
}
.store-catlog-span:hover {
  text-decoration: underline;
}
.store-contact-div {
  text-transform: capitalize;
  font-weight: 700;
  margin-left: 5px;
  color: #bdbdbd;
}
.store-contact-a {
  text-transform: lowercase;
  color: white;
  font-size: 14px;
  font-weight: 700;
  margin-left: 3px;
}

/* store products component */
.store-products-top-product-div-cell {
  overflow: hidden;
  border: 1px solid #333333;
  border-radius: 5px;
  max-height: 190px;
  background-color: #000;
}
.store-products-top-product-div-image-cell {
  overflow: hidden;
  border-radius: 5px;
  height: 90%;
}
.store-products-top-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.store-products-top-product-name {
  color: white;
  font-size: 18px;
  text-transform: capitalize;
  height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0px;
  margin-top: 10px;
}
.store-products-top-product-div-content-cell {
  height: 100%;
  margin: 0px 0px 0px 7px;
}
.store-products-product-description {
  max-height: 26%;
  overflow: hidden;
  line-height: 16px;
  font-family: monospace;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  margin: 0px 0px 3px 0px;
  padding-top: 5px;
}
.store-products-bottom-btn-div {
  padding-top: 3px;
  border-top: 1px solid #1d1a1a;
}
.store-item-div {
  background-color: burlywood;
  border-radius: 5px;
}
.store-item-back-img-div {
  width: 100%;
  height: 150px;
  background-color: #080808;
  border-radius: 5px;
  position: relative;
}
.store-item-back-img-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  object-position: center;
}
.store-item-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 150px;
  background: #0000005c;
  border-radius: 5px 5px 0px 0px;
}
.store-item-icon {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  top: 70%;
  left: 40%;
  background-color: white;
}
.store-item-icon-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.store-item-name-h3 {
  color: black;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  text-transform: capitalize;
  vertical-align: middle;
  text-align: center;
  margin: 20px 10px 0px 10px;
}
.store-item-name-h3:hover {
  color: black;
}
.store-item-desc-div {
  padding: 5px;
  max-height: 95px;
  overflow: hidden;
}
.store-item-desc-h3 {
  color: saddlebrown;
  margin: 0px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  vertical-align: middle;
  text-align: center;
}
/* store add pages */
.store-add-sharpner {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
}
.storetextfieldcsshandler {
  width: 100%;
  margin: 10px 2px;
}
.store_field_adjuster {
  margin: 10px;
}
/* store page ends */
/* catlog page */
.catlog-heading-div {
  text-transform: capitalize;
}
.catlog-heading-h2 {
  text-transform: capitalize;
  font-size: 40px;
  color: saddlebrown;
}
.catlog-item-link {
  width: 100%;
  height: 100%;
}
.catlog-item-div {
  overflow: hidden;
  height: 270px;
  border-radius: 7px;
  background-color: #f0f0f0;
  position: relative;
  cursor: pointer;
}
.catlog-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.catlog-item-div-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: linear-gradient(180deg, #eeecec00, #f0f0f000, #151515b3, #191919);
  cursor: pointer;
  transition-delay: 0.2s;
  transition-timing-function: ease-out;
}
.catlog-item-div-overlay:hover {
  /* background: linear-gradient(
    180deg,
    #000000cf,
    #000000d4,
    #000000e3,
    #000000d4
  ); */
}
.catlog-name-h2 {
  position: relative;
  top: 70%;
  text-transform: capitalize;
  color: #ffffff;
  padding: 5px 40px;
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  font-family: cursive;
  cursor: pointer;
  transition-delay: 0.2s;
  transition-timing-function: ease-in-out;
}
.catlog-name-h2:hover {
  color: darkorange;
}
/* catlog ends */
.ReportDialogContent::-webkit-scrollbar-thumb {
  background-color: #b11313;
  border-radius: 10px;
  cursor: move;
}
.ReportDialogContent::-webkit-scrollbar {
  width: 10px;
  background-color: #101010;
  border-radius: 10px;
}
/* screen optimization */
.signiniconheader {
  display: none;
}
/* adding section css */
.addtextfield > div > input:focus {
  background-color: #00000000;
  color: black;
  border: 0px;
}
@media screen and (max-width: 850px) {
  .mdl-layout-title {
    font-size: 12px;
    display: none;
  }
  .mdl-layout-title-mble {
    display: block;
  }
  .mdl-layout__header-row {
    padding: 0px 16px;
  }
  .top_search_field {
    margin: 0px 0px 0px 10px;
  }

  .textbox {
    outline: 0;
    height: 30px;
    width: -webkit-fill-available;
    border-radius: 5px;
  }
  .SeachButton_top {
    display: none;
    margin: 0px;
  }
  .search_field_adjuster {
    display: none;
    margin: 0px;
  }
  .top_header_user_content {
    width: 24px;
    margin: 5px 0px 5px 5px;
  }
  .store-info-grid-div {
    top: 1%;
  }
  .store-name-div {
    margin: 0px 10px;
    width: 100%;
  }
  .store-name-h1 {
    font-size: 24px;
    margin-top: 5px;
    width: 100%;
    height: 30px;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    /* margin-left: 35%; */
  }
  .store-catlog-h5 {
    height: 24px;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .store-website-link-a {
    display: none;
  }
  .store-description-h1 {
    display: block;
    max-height: 73px;
  }
  .store-description-div {
    display: block;
    margin-bottom: 0px;
    margin-left: 10px;

    text-align: center;
    max-height: 100px;
    vertical-align: middle;
  }
  .store-social-grid-div {
    top: 0%;
  }
  .store-save-bookmark-grid-div-cell_1 {
    display: none;
    margin: 0px;
  }
  .store-social-grid-div-cell {
    position: relative;
    left: 0%;
    background-color: #000000;
  }
  .store-catlog-span {
    font-size: 12px;
    vertical-align: middle;
    height: 24px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .store-save-bookmark-grid-div-cell {
    width: 100%;
  }
  .SigninBtnHeader {
    display: none;
  }
  .signiniconheader {
    display: block;
    margin-top: "8px";
  }
}

.text-danger {
  color: red;
}
/* image container */
.image-container {
  width: 300px;
  height: 300px;
}
.image-yoyo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
